import { html, css, LitElement } from 'lit-element';
import '../../analog-clock/analog-clock.js';

export class PageMain extends LitElement {
  static get styles() {
    return css`
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
      }
      .center {
        text-align: center;
      }
      div {
        margin-top: 10vh;
        width: 80vw;
        max-width: 600px;
      }
    `;
  }

  static get properties() {
    return {
      title: { type: String },
      logo: { type: Function },
    };
  }

  constructor() {
    super();
    this.title = 'Klok kijken';
    this.logo = html``;
  }

  render() {
    return html`
      <div>
        <img src="assets/logo.svg" />
        <h1 class="center">Klok kijken</h1>
        <p class="center">
          © 2020 WorkingWeb
        </p>
      </div>
    `;
  }
}
