import { html, css, LitElement } from 'lit-element';
import { Machine, assign, interpret } from 'xstate';

import '../../analog-clock/analog-clock.js';
import '../../progress-bar/progress-bar.js';
import confetti from 'canvas-confetti/dist/confetti.module.mjs';
import '@material/mwc-radio';
import '@material/mwc-formfield';

export class GamePlay extends LitElement {
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
      }
      svg {
        display: block;
        margin: auto;
      }
      div {
        margin: 5vh;
        width: 80vw;
        max-width: 600px;
      }
      mwc-formfield {
        display: block;
      }
      mwc-linear-progress {
        width: 200px;
      }
      progress-bar {
        width: 100%;
        height: 20px;
        display: block;
        margin: 5vh 0 1em 0;
      }
      .stand {
        margin: auto;
        margin-top: 1em;
        text-align: center;
        font-size: 2.8em;
      }
      .uitslag {
        font-size: 3em;
        text-align: center;
      }
    `;
  }

  static get properties() {
    return {
      minutes: Number,
      state: {
        type: Object,
      },
      gametype: String,
    };
  }

  constructor() {
    // @todo misschien leuk om de overgang van uur naar uur te animeren door de minuten en de uren op te laten lopen tot je er bent?
    super();

    this.addEventListener('gametype', (e) => {
      this.gametype = e.detail;
      // new gametype, restart state
      clearInterval(this._confettinterval);
      this.gameService.send('RESTART');
    });

    const addCorrect = assign({
      correct: context => context.correct + 1,
    });

    const addWrong = assign({
      wrong: context => context.wrong + 1,
    });

    // Guard to check if the glass is full
    function gameOver(context) {
      return context.correct + context.wrong >= 10;
    }

    this._confettinterval = undefined;

    const gameMachine = Machine(
      {
        id: 'game',
        context: {
          correct: 0,
          wrong: 0,
          hourCollection: [],
          hours: 0,
          minutes: 0,
        },
        initial: 'waiting',
        states: {
          waiting: {
            on: {
              SET_GAME: {
                target: 'setgame',
              },
            },
          },
          restart: {
            after: {
              1000: 'setgame',
            },
          },
          setgame: {
            invoke: {
              id: 'generategame',
              src: () => this._generateGame(),
              onDone: {
                target: 'playing',
                actions: assign({
                  // @todo reset context here by using action or by assignments like below?
                  hourCollection: (_, event) => event.data.hourCollection,
                  hours: (_, event) => event.data.hours,
                  minutes: (_, event) => event.data.minutes,
                }),
              },
            },
          },
          playing: {
            on: {
              // Transient transition
              '': {
                target: 'showresults',
                cond: 'gameOver',
              },
              WRONG: {
                target: 'wrongresult',
                actions: 'addWrong',
              },
              CORRECT: {
                target: 'correctresult',
                actions: 'addCorrect',
              },
              RESTART: {
                target: 'restart',
                actions: assign({
                  correct: () => 0,
                  wrong: () => 0,
                }),
              },
            },
          },
          wrongresult: {
            after: {
              1000: 'setgame',
            },
          },
          correctresult: {
            after: {
              1000: 'setgame',
            },
          },
          showresults: {
            on: {
              RESTART: {
                target: 'restart',
                actions: assign({
                  correct: () => 0,
                  wrong: () => 0,
                }),
              },
            },
          },
        },
      },
      {
        actions: { addCorrect, addWrong },
        guards: { gameOver },
      },
    );

    this.gameService = interpret(gameMachine)
      .onTransition(state => {
        // Update the current machine state when a transition occurs
        if (state.changed) {
          this.state = state;
          console.log('state changed ', state.value);
        }
      })
      .start();
  }

  _generateGame() {
    const quarters = [0, 15, 30, 45];
    const fivers = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    const halfhours = [0, 30];
    let minutes;
    switch (this.gametype) {
      default:
      case 'full-hours':
        minutes = 0;
        break;
      case 'half-hours':
        minutes = halfhours[Math.floor(Math.random() * halfhours.length)];
        break;
      case 'quarter-hours':
        minutes = quarters[Math.floor(Math.random() * quarters.length)];
        break;
      case 'five-minutes':
        minutes = fivers[Math.floor(Math.random() * fivers.length)];
        break;
      case 'minutes':
        minutes = Math.floor(Math.random() * (59 - 0) + 0);
        break;
    }
    this.minutes = minutes;
    const hourNames = [
      'één',
      'twee',
      'drie',
      'vier',
      'vijf',
      'zes',
      'zeven',
      'acht',
      'negen',
      'tien',
      'elf',
      'twaalf',
      'dertien',
      'veertien',
    ];
    const hourArray = [];
    const hourCollection = [];

    let prefix = '';
    let hourname = '';
    let i = 0;
    while (hourArray.length < 4) {
      const randomHour = Math.floor(Math.random() * (12 - 1 + 1)) + 1;
      if (hourArray.indexOf(randomHour) < 0) {
        hourArray[i] = randomHour;
        let minutesname = '';
        if (this.minutes > 0) {
          if (this.minutes <= 15) {
            minutesname = hourNames[this.minutes - 1];
            prefix = ' over ';
          }
          if (this.minutes > 19 && this.minutes < 30) {
            minutesname = hourNames[30 - this.minutes - 1];
            prefix = ' voor half ';
          }
          if (this.minutes > 30 && this.minutes < 45) {
            minutesname = hourNames[this.minutes - 31];
            prefix = ' over half ';
          }
          if (this.minutes >= 45) {
            minutesname = hourNames[60 - this.minutes - 1];
            prefix = ' voor ';
          }
          if (this.minutes === 15 || this.minutes === 45) {
            // override minutes, prefix is already set correct before
            minutesname = ' kwart ';
          }
          if (this.minutes === 30) {
            prefix = 'half ';
            minutesname = '';
          }
          if (randomHour === 12 && this.minutes > 15) {
            hourname = minutesname + prefix + hourNames[0];
          } else {
            hourname =
              minutesname +
              prefix +
              (this.minutes > 15 ? hourNames[randomHour] : hourNames[randomHour - 1]);
          }
        } else {
          hourname = `${hourNames[randomHour - 1]} uur`;
        }
        // capitalize first letter
        hourname = hourname.toUpperCase().charAt(0) + hourname.substring(1);
        hourCollection.push({ name: hourname, val: `${randomHour}${this.minutes}` });
        i += 1;
      }
    }
    const hours = hourArray[Math.floor(Math.random() * hourArray.length)];
    return new Promise(resolve => resolve({ hourCollection, minutes: this.minutes, hours }));
  }

  selectRadio(target) {
    // make sure the checkbox is very shortly shown
    setTimeout((radio) => {
      radio.removeAttribute('checked');
      if (`${this.state.context.hours}${this.state.context.minutes}` === radio.value) {
        this.gameService.send('CORRECT');
      } else {
        this.gameService.send('WRONG');
      }
    }
    , 400, target);
  }

  // eslint-disable-next-line class-methods-use-this
  _happySmiley() {
    return html`
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 16 16"
          id="happysmiley"
          width="80%"
          height="80%"
        >
          <path
            fill="#000000"
            d="M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM8 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM8 9.356c1.812 0 3.535-0.481 5-1.327-0.228 2.788-2.393 4.971-5 4.971s-4.772-2.186-5-4.973c1.465 0.845 3.188 1.329 5 1.329zM4 5.5c0-0.828 0.448-1.5 1-1.5s1 0.672 1 1.5c0 0.828-0.448 1.5-1 1.5s-1-0.672-1-1.5zM10 5.5c0-0.828 0.448-1.5 1-1.5s1 0.672 1 1.5c0 0.828-0.448 1.5-1 1.5s-1-0.672-1-1.5z"
          ></path>
        </svg>
        <div class="stand">${this.state.context.correct} goed, ${this.state.context.wrong} fout</div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _sadSmiley() {
    return html`
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 16 16"
          id="sadsmiley"
          width="80%"
          height="80%"
        >
          <path
            fill="#000000"
            d="M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM8 1.5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zM4 5c0-0.552 0.448-1 1-1s1 0.448 1 1c0 0.552-0.448 1-1 1s-1-0.448-1-1zM10 5c0-0.552 0.448-1 1-1s1 0.448 1 1c0 0.552-0.448 1-1 1s-1-0.448-1-1zM4.998 12.199l-1.286-0.772c0.874-1.454 2.467-2.427 4.288-2.427s3.413 0.973 4.288 2.427l-1.286 0.772c-0.612-1.018-1.727-1.699-3.002-1.699s-2.389 0.681-3.002 1.699z"
          ></path>
        </svg>
        <div class="stand">${this.state.context.correct} goed, ${this.state.context.wrong} fout</div>
    `;
  }

  _gameScreen() {
    return html`
        <analog-clock
          .hours="${this.state.context.hours}"
          .minutes="${this.state.context.minutes}"
        ></analog-clock>
      <progress-bar .correct="${this.state.context.correct}" .incorrect="${this.state.context.wrong}"></progress-bar>

      <h1>Hoe laat is het?</h1>
      ${this.state.context.hourCollection.map(
        items => html`
          <mwc-formfield label="${items.name}">
            <mwc-radio @change="${e => this.selectRadio(e.target)}" value="${items.val}"></mwc-radio>
          </mwc-formfield>
        `,
      )}
    `;
  }

  _showResults() {
    return html`
        <h1 class="uitslag">Uitslag</h1>
        <div class="stand">${this.state.context.correct} goed, ${this.state.context.wrong} fout</div>
    `;
  }

  firstUpdated() {
    // we know the values we need for initiating the game (coming from the parent) have now been set
    this.gameService.send('SET_GAME');
  }

  // eslint-disable-next-line class-methods-use-this
  _confetti() {
    const end = Date.now() + 15 * 1000;

    // eslint-disable-next-line consistent-return
    this._confettinterval = setInterval(() => {
      if (Date.now() > end) {
        return clearInterval(this._confettinterval);
      }

      confetti({
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        shapes: ['square'],
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2,
        },
      });
    }, 200);
  }

  // eslint-disable-next-line class-methods-use-this
  gameScreen(mainoutput) {
    return html`<div>${mainoutput}</div>`;
  }

  render() {
    let renderoutput;

    if (this.state) {
      switch (this.state.value) {
        default:
        case 'playing':
          renderoutput = this._gameScreen();
          break;
        case 'wrongresult':
          renderoutput = this._sadSmiley();
          break;
        case 'correctresult':
          renderoutput = this._happySmiley();
          break;
        case 'showresults':
          if (this.state.context.correct === 10) {
            this._confetti();
          }
          renderoutput = this._showResults();
          break;
      }
    }
    return this.gameScreen(renderoutput);
  }
}


