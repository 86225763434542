import { html, LitElement } from 'lit-element';

export class ProgressBar extends LitElement {
  static get properties() {
    return {
      correct: Number,
      incorrect: Number,
    };
  }

  render() {
    // language=HTML
    return html`
      <style>
      .grid {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        background: #888;
        height: 20px;
      }
      .correct {
        background-color: ${this.correct ? 'green' : '#888' };
        grid-column: 1 / span ${this.correct};
        display: ${this.correct ? 'block' : 'none'};
      }
      .incorrect {
        background-color: ${this.incorrect ? 'red' : '#888' };
        grid-column: ${this.correct ? this.correct + 1 : 1} / span ${this.incorrect};
        display: ${this.incorrect ? 'block' : 'none'};
      }
     </style>
      <div class="grid">
        <div class="correct"></div>
        <div class="incorrect"></div>
      </div>
    `;
  }
}
