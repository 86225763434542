import { LitElement, html, css } from 'lit-element';
import { Machine, interpret } from 'xstate';

import '@material/mwc-drawer/mwc-drawer.js';
import '@material/mwc-top-app-bar/mwc-top-app-bar.js';
import '@material/mwc-icon-button/mwc-icon-button.js';

import '../../page-main/page-main.js';
import '../../game-play/game-play.js';

export class KlokKijken extends LitElement {
  static get properties() {
    return {
      title: { type: String },
      page: { type: String },
      menuShown: { type: Boolean },
      sound: { type: Boolean },
    };
  }

  constructor() {
    super();

    const toggleMenuMachine = Machine({
      id: 'toggle',
      initial: 'hidden',
      states: {
        hidden: { on: { TOGGLE: 'shown', SHOW: 'shown' } },
        shown: { on: { TOGGLE: 'hidden', HIDE: 'hidden' } },
      },
    });

    const toggleMusicMachine = Machine({
      id: 'toggle',
      initial: 'on',
      states: {
        off: { on: { TOGGLE: 'on' } },
        on: { on: { TOGGLE: 'off' } },
      },
    });

    const mainScreenMachine = Machine({
      id: 'mainscreen',
      initial: 'title',
      states: {
        title: { on: { START_GAME: 'game' } },
        game: { on: { END_GAME: 'title' } },
      },
    });

    this.sound = toggleMusicMachine.initialState.value;

    this.menuShown = toggleMenuMachine.initialState.value;

    // Machine instance with internal state, @todo assignment is niet pure function nu denk ik...
    this.toggleMenuService = interpret(toggleMenuMachine)
      .onTransition(state => {
        this.menuShown = state.value;
      })
      .start();

    // Machine instance with internal state, @todo assignment is niet pure function nu denk ik...
    this.toggleMusicService = interpret(toggleMusicMachine)
      .onTransition(state => {
        this.sound = state.value;
        this._handleMusicPlayer(this.sound);
      })
      .start();

    // Machine instance with internal state, @todo assignment is niet pure function nu denk ik...
    this.mainScreenMachineService = interpret(mainScreenMachine)
      .onTransition(state => {
        this.mainscreen = state.value;
      })
      .start();
  }

  firstUpdated() {
    const drawer = this.shadowRoot.querySelector('mwc-drawer');
    drawer.addEventListener('MDCDrawer:closed', () => {
      this.toggleMenuService.send('CLOSE');
    });
  }

  _handleMusicPlayer(sound) {
    const player = this.shadowRoot.getElementById('player');
    if (player) {
      if (sound === 'on') {
        // if the player cannot be used yet toggle it back (@todo is this the cleanest way of solving this state?)
        player.play().catch(() => this.toggleMusicService.send('TOGGLE'));
      } else {
        player.pause();
      }
    }
  }

  _renderPage() {
    return html`
      <page-main ?hidden=${this.mainscreen !== 'title'}></page-main
      ><game-play ?hidden=${this.mainscreen === 'title'}></game-play>
    `;
  }

  _clickGameLink(ev) {
    ev.preventDefault();
    this.shadowRoot
      .querySelector('game-play')
      .dispatchEvent(new CustomEvent('gametype', { detail: ev.target.hash.substring(1) }));
    this.toggleMenuService.send('HIDE');
    this.mainScreenMachineService.send('START_GAME');
  }

  render() {
    return html`
      <mwc-drawer hasHeader type="modal" ?open="${this.menuShown === 'shown'}">
        <span slot="title">Menu</span>
        <div>
          <ul>
            <li>
              <a name="full-hours" @click="${this._clickGameLink}" href="#full-hours">Hele uren</a>
            </li>
            <li>
              <a name="half-hours" @click="${this._clickGameLink}" href="#half-hours">Halve uren</a>
            </li>
            <li>
              <a name="quarter-hours" @click="${this._clickGameLink}" href="#quarter-hours"
                >Kwartieren</a
              >
            </li>
            <li>
              <a name="five-minutes" @click="${this._clickGameLink}" href="#five-minutes"
                >Vijf minuten</a
              >
            </li>
          </ul>
          <mwc-icon-button
            icon=${this.sound === 'on' ? 'volume_up' : 'volume_off'}
            @click=${() => {
              this.toggleMusicService.send('TOGGLE');
            }}
          ></mwc-icon-button>
        </div>
        <div slot="appContent" id="content">
          <mwc-top-app-bar>
            <mwc-icon-button
              slot="navigationIcon"
              icon="menu"
              @click=${() => {
                this.toggleMenuService.send('TOGGLE');
              }}
            ></mwc-icon-button>
            <div slot="title">${this.title}</div>
          </mwc-top-app-bar>
          ${this._renderPage()}
          <div></div>
        </div>
      </mwc-drawer>
      <audio id="player" src="assets/riding_high.mp3" type="audio/mpeg" loop autoplay></audio>
    `;
  }

  static get styles() {
    return [
      // language=CSS
      css`
        #content {
          width: 100%;
        }
        [hidden] {
          display: none !important;
        }
      `,
    ];
  }
}
